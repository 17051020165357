html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
}

.Resizer {
    background: #f5f5f5;
    z-index: 1101;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.Resizer.vertical {
    width: 11px;
    cursor: col-resize;
    height: 100%;
}

