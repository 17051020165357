/*******************************
        Blockly Toolbox
*******************************/
.blocklyToolboxDiv {
    background: #ececec !important;
    color: #575E75;
    border-right: solid #c6c6c6 0.5px;
    z-index: 40;
}

.blocklyToolboxContents {
    padding: .25em;
}

/*******************************
        Toolbox tree row
*******************************/
.blocklyTreeRowContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

div.blocklyTreeRow {
    height: initial;
    padding: 3px;
    margin: 0;
    padding-top: .5rem;
    border-radius: 4px;
    cursor: pointer;
}

div.blocklyTreeRow:not(.blocklyTreeSelected):hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.blocklyTreeSelected {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.blocklyToolboxContents .category {
    margin: 3px 0;
    border-radius: 4px;
}

/*******************************
        Toolbox tree label
*******************************/
/* Blockly toolbox font size same as the page font */
span.blocklyTreeLabel {
    font-weight: 300;
    font-size: 1.15rem;
    cursor: pointer;
}

/*******************************
        Toolbox selected
*******************************/
.blocklyTreeSelected .blocklyTreeLabel {
    color: #fff;
}

.blocklyTreeSelected .blocklyTreeIcon {
    color: #fff;
}

/*******************************
        Toolbox tree icon
*******************************/
/* Blockly toolbox icons */
span.blocklyTreeIcon {
    background: none !important;
    opacity: 1;
    margin: 0em 0.25em 0em 0.25em;
    width: 30px;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
    font-size: 1.3rem;
    height: 100%;
    display: none;
    vertical-align: middle;
}

div.blocklyTreeIcon span {
    vertical-align: middle;
}

.blocklyTreeIcon.blocklyTreeIconfunctions {
    font-family: xicon !important;
}

.blocklyFlyoutLabelIcon.blocklyFlyoutIconfunctions {
    font-family: xicon !important;
}

/*******************************
    Blockly Accessibility
*******************************/
.blocklyTreeRow:focus {
    outline: none;
}

.blocklyTreeRow:not(.blocklyTreeSelected):focus {
    background-color: #e4e4e4;
}

/* Opacity of blockly flyout background */
path.blocklyFlyoutBackground {
    fill: #505050 !important;
    fill-opacity: 0.9 !important;
}

.blocklyText {
    font-family: monospace;
}

/* Fix z-index  */
.swal2-container {
    z-index: 2000 !important;
}

#playArea > header {
    z-index: auto;
}

/*LevelLoadModal*/

.levelLoadModalList {
    overflow-y: auto;
    max-height: 400px;
    width: 100%;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.levelLoadModalListSection {
    background-color: inherit;
}

.levelLoadModalListSection ul {
    background-color: inherit;
    padding: 0;
}

@keyframes blinkingBackground {
    0% {
        background-color: #f5f5f5;
    }
    70% {
        background-color: #cb253d;
    }
    80% {
        background-color: #cb253d;
    }
}

@media (min-width: 576px) {
    .levelLoadModalList {
        max-height: 200px;
    }
}

@media (min-width: 768px) {
    .levelLoadModalList {
        max-height: 300px;
    }
}

@media (min-width: 992px) {
    .levelLoadModalList {
        max-height: 350px;
    }
}

/* Cubi-Category-Icons CSS-Font */
@font-face {
    font-family: "cubi-category-icons";
    src: url("fonts/cubi-category-icons.eot");
    src: url("fonts/cubi-category-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/cubi-category-icons.woff") format("woff"),
    url("fonts/cubi-category-icons.ttf") format("truetype"),
    url("fonts/cubi-category-icons.svg#cubi-category-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "cubi-category-icons" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="category-icon-"]:before,
[class*=" category-icon-"]:before {
    font-family: "cubi-category-icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.blocklyTreeRow {
    display: flex;
    align-items: center;
    height: 25px;
}

.blocklyTreeRow[style] {
    border-left: none !important;
}

.category-icon {
    font-size: 25px;
    color: #ffffff;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 10px;
}

.category-icon-control:before {
    content: "\61";
}

.category-icon-events:before {
    content: "\62";
}

.category-icon-functions:before {
    content: "\63";
}

.category-icon-looks:before {
    content: "\64";
}

.category-icon-loops:before {
    content: "\65";
}

.category-icon-math:before {
    content: "\66";
}

.category-icon-movement:before {
    content: "\67";
}

.category-icon-senses:before {
    content: "\68";
}

.category-icon-variables:before {
    content: "\69";
}

.category-icon-gamemaster:before {
    content: "\6a";
}

.category {
    color: #ffffff;
}

.category-control {
    background-color: rgba(55, 119, 113, 1.0);
}

.category-events {
    background-color: rgba(211, 34, 68, 1.0);
}

.category-functions {
    background-color: rgba(154, 92, 166, 1.0);
}

.category-looks {
    background-color: rgba(201, 138, 56, 1.0);
}

.category-loops {
    background-color: rgba(94, 153, 209, 1.0);
}

.category-math {
    background-color: rgba(108, 197, 81, 1.0);
}

.category-movement {
    background-color: rgba(20, 117, 187, 1.0);
}

.category-senses {
    background-color: rgba(206, 106, 133, 1.0);
}

.category-gamemaster {
    background-color: rgba(57, 73, 107, 1.0);
}

.category-variables {
    background-color: rgba(166, 92, 129, 1.0);
}

/* Blockly Contextmenu */
.blocklyWidgetDiv .blocklyMenu{
    max-height: none;
}
.blocklyMenuItem {
    padding: 6px 10px 6px 3px;
}
.blocklyMenuItemIconWrapper {
    display: inline-block;
    margin-right: 5px;
    min-width: 20px
}
.blocklyMenuItemIcon {
    vertical-align: middle;
    max-width: 20px;
}
.blocklyMenuItemIconDisabled {
    filter: grayscale(1);
    opacity: 0.5;
}
.blocklyMenuItemText {
    vertical-align: middle;
}