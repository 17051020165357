#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    z-index: 15000;
    text-align: center;
}

#preloader > div {
    width: 100%;
    height: 50%;
    animation: Rotate 2s linear infinite;
    background-repeat: no-repeat;
    background-size: 10%;
    background-position: center bottom;
}

@keyframes Rotate {
    0% {
        -webkit-transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
    }
}
